import auth0 from 'auth0-js';
import { navigate } from 'gatsby';

const makeNonce = (length) => {
  var result = '';
  var characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
 
  return result;
};

const isBrowser = typeof window !== 'undefined';

const auth = isBrowser
  ?
    new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: 'token id_token',
      scope: 'openid profile email https://editorial-landing.publicdomainreview.org/user_metadata',
      useRefreshTokens: true,
      cacheLocation: "localstorage",
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
};

let user = {};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }
  
  return localStorage.getItem('isLoggedIn') === "true";
};

export const login = (returnPath) => {
  if (!isBrowser) {
    return;
  }
  
  if (returnPath && returnPath.length > 0) {
    const nonce = makeNonce(8);
    localStorage.setItem(nonce + '-returnPath', returnPath);
    
    auth.authorize({ state: nonce });
    return;
  }
  
  auth.authorize();
};

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate('/');
    cb();
    return;
  }
  
  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;
    auth.client.userInfo(authResult.accessToken, function(err, fetchedUser) {
      user = fetchedUser;
      localStorage.setItem('isLoggedIn', true);
      
      if (window.location.pathname === '/callback' || window.location.pathname === '/callback/') {
        const returnPath = localStorage.getItem(authResult.state + '-returnPath');
        
        if (returnPath) {
          localStorage.removeItem(authResult.state + '-returnPath');
          navigate(returnPath);
        } else {
          navigate('/app/dashboard');
        }
      }
      cb();
    });
  }
};

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }
  
  auth.parseHash(setSession());
};

export const getUserMetadata = () => {
  return user['https://editorial-landing.publicdomainreview.org/user_metadata'];
};

export const getProfile = () => {
  return user;
};

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback();
  
  auth.checkSession({}, setSession(callback));
};

export const logout = () => {
  localStorage.setItem("isLoggedIn", false);
  auth.logout(
    {
      returnTo: process.env.GATSBY_HOME_URL,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
    }
  );
};